<!-- pc端 -->
<template>
	<div class="content" :style="{ backgroundImage: 'url(' + bgtest + ')' }" :class="[gongsi.window_position == 1 ? 'boxOne' : '',
	gongsi.window_position == 2 ? 'boxTwo' : '',
	gongsi.window_position == 3 ? 'boxThree' : '',
	gongsi.window_position == 4 ? 'boxFour' : '',
	gongsi.window_size == 1 ? 'sizeOne' : '',
	gongsi.window_size == 2 ? 'sizeTwo' : '',
	]">
			<div class="liuYan_model" v-if='lyShow'>
			<div class="form_liuyan">
				<div class="titles">
					<div>留言板</div>
					<img @click="lyShow = false" src="../../static/imgs/del.png">
				</div>
				<div class="form_box">
					<el-form :label-position="labelPosition" class="demo-ruleForm" :rules="rules" label-width="80px"
						:model="formLabelAlign" :status-icon='true'>
						<el-form-item label="姓名:" prop="name">
							<el-input v-model="formLabelAlign.name" class="ipt"></el-input>
						</el-form-item>
						<el-form-item label="联系方式:" prop="region">
							<el-input v-model="formLabelAlign.region" type="number"
								oninput="if(value.length > 11) value=value.slice(0, 11)" class="ipt"></el-input>
						</el-form-item>
						<el-form-item label="留言:" prop="text">
							<el-input v-model="formLabelAlign.text" type="textarea" :rows="8" class="ipt"></el-input>
						</el-form-item>
					</el-form>
					<div class="btnClik">
						<div class="Back" @click="lyShow = false">取消</div>
						<div class="Send" @click="send_leave">提交留言</div>
					</div>
				</div>

			</div>
		</div>
		<div class="pup_image" v-if="pupImageShow">
			<img :src="serverStr.answer" alt="" srcset="" @click="open_maxImg" />
		</div>
		<div class="logo_name" v-if="key && gongsi.logo && gongsi.enterprise_name">
			<img :src="gongsi.logo" alt="" width="40" height="40" class="src">
			<div>{{ gongsi.enterprise_name }}</div>
		</div>
		<div class="pup_image" v-if="pupImageShow">
			<img :src="serverStr.answer" alt="" srcset="" @click="open_maxImg" />
		</div>
		<renwu v-if="gongsi" :environment="gongsi.environment" :seat="gongsi.seat" :parallel="gongsi.parallel"
			:modelURL="gongsi.digital.model_url" @handleClick="handleClick1"></renwu>
		<div class="quest">
			<div class="bg_list">
				<div class="biaoti">猜你想问</div>
				<div v-for="(item, index) in likeList" class="box1" @click="wen(item.question)">
					<div class="icon_D"></div>
					<div class="daan">{{ item.question }}</div>
				</div>
			</div>
		</div>
		<div class="rightBox">
			<div class="plant" v-if='recognizedText || result'>
				<div class="date">{{ date1.toLocaleString() }}</div>
				<div class="wenti">{{ recognizedText || result }}</div>
			</div>
			<div class="serve" v-if="state == 1">
				<div class="date">{{ date2.toLocaleString() }}</div>
				<div class="huifu">{{ serverStr }}</div>
			</div>
			<div class="serve" v-if="serverStr.type == 0">
				<div class="date">{{ date2.toLocaleString() }}</div>
				<div class="huifu">{{ serverStr.answer }}</div>
			</div>
			<div class='serve ' v-if="serverStr.type == 2" @click="link_href(serverStr.answer)">
				<div class="date">{{ date2.toLocaleString() }}</div>
				<div class="huifu active">
					点我跳转
				</div>
			</div>
			<div class='serve ' v-if="serverStr.type == 3">
				<div class='huifu'>
					<img class="imgMsg" :src="serverStr.answer" height="200" width="200" @click="open_maxImg" />
				</div>
			</div>
			<div class='serve' v-if="serverStr.length > 0 && this.state == '0'">
				<div class="date">{{ date2.toLocaleString() }}</div>
				<div class="huifu wentiBox">
					<div class="title">你是否想要了解以下问题：</div>
					<div v-for="(item, index) in serverStr" @click="question_fun(item)" class="msg">
						<div class="names">{{ item.question }} </div>
					</div>
				</div>
			</div>
		</div>
		<div class="pupBox" v-if="serverStr.type == 1">
			<div class="video_box">
				<video :src="serverStr.answer" controls autoplay></video>
				<div class="icon_close" @click="del">x</div>
			</div>
		</div>

		<div class="container">
			<div class='button_menu'>
				<div id="app">
					<div class="voice-input-button-wrapper">
						<voice-input-button appId="5e7318f9" apiKey="7f067847197d323ac97da9fa173d461a"
							apiSecret="267eb2c7702ddabc3c419bd6931c7f04" v-model="result" @record="showResult"
							@record-start="recordStart" @record-stop="recordStop" @record-blank="recordNoResult"
							@record-failed="recordFailed" @record-ready="recordReady" @record-complete="recordComplete"
							interactiveMode="press" color="#3f8ceb" tipPosition="top" tipTextColor="#ffffff" :vad_eos="300">
							<template slot="no-speak">没听清您说的什么</template>
							<template slot="recording">好，请讲</template>
							<template slot="wait">请稍后</template>
						</voice-input-button>
					</div>
				</div>
			</div>
			<div class='List'>
				<div v-for='(item, index) in qylist' v-if="qylist" @click="Listitem(item)" style="cursor:pointer;">{{
					item.question }}</div>
			</div>
			<div class="bottom_border"></div>
			<div class='edit_text'>
				<textarea placeholder="您想咨询什么？" v-model="chatStr">  </textarea>
			</div>

			<div class='send_btn'>
				<div class="clear" @click="Clear_screen">
					<img src="../../static/imgs/qingping.png" alt="" srcset="">
					清屏
				</div>
				<div class="Ly" @click="liuyan">留言</div>
				<div @click="send" class="pcSend">发送</div>
			</div>
		</div>
	
		<div class="PYsxY">
			<div>该产品由嗨媒体AI支持，内容仅供参考，</div>
			<div class="line_two">
				不代表本公司的态度或观点 请遵守<div class="zc" @click="goPageZc">《隐私政策》</div>和<div class="xy" @click="goPageXy">《隐私协议》
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axios from "axios"
import console, { log } from "console"
import { isVideoURL } from '../utils/isVideoURL.js'
import voiceInputButton from 'voice-input-button2'
import ttsRecorder from "../assets/audio"
import renwu from "../components/renwu.vue"
import { timingSafeEqual } from "crypto"
// const tts = new ttsRecorder() // 语音合成
export default {
	components: {
		voiceInputButton,
		renwu
	},
	data() {
		return {
			title: 'Hello',
			qylist: [{ question: '公司简介' }, { question: '企业展厅' }, { question: '企业视频' }],
			likeList: ['你是谁', '公司简介', '你来自哪里'],// 猜你想问
			result: '', // 转换完的文字
			recognizedText: '',
			chatStr: '', // 客户端问题
			serverStr: '', // 服务端问题
			key: '', // 不同企业 对应不同的标识
			date1: new Date(),
			date2: '',
			isShow: false,
			gongsi: "",
			tts: new ttsRecorder(),
			state: '3',
			pupImageShow: false,
			bgtest: '',
			timer: null,
			showMessage: false,
			maxLength: 11,
			lyShow: false,
			labelPosition: 'top',
			formLabelAlign: {
				name: '',
				region: '',
				text: ''
			},
			rules: {
				name: [
					{ required: true, message: '请输入姓名', trigger: 'blur' },
					{ min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
				],
				region: [
					{ required: true, message: '请输入手机号', trigger: 'blur' },
					{ min: 11, max: 11, message: '长度最长在11个字符', trigger: 'blur' }
				],
				text: [
					{ required: true, message: '请输入留言', trigger: 'blur' }
				]
			}

		}
	},
	onLoad() {
		document.body.style.overflow = 'hidden';

	},
	computed: {
		minutesSinceLastClick() {
			return Math.floor((Date.now() - this.lastClickTime) / 1000 / 60);
		}
	},
	// 监听用户多久操作
	watch: {
		showMessage: {
			handler(n) {
				if (n == true) {
					var t = this
					t.play('主人有什么需要帮忙的吗？你可以说，讲个故事，来一张图片，说个笑话')
				}
			},
			deep: true
		},
	},
	created() {


	},
	mounted() {

		// 访问时是否带key
		if (this.$route.query.key) {
			this.key = this.$route.query.key;
		} else {
			this.key = ''
		}
		this.startTimer()
		this.logoName(this.key)
		this.answer_like()
	},
	methods: {
		handleClick1(e) {

			if (e == true) {
				this.result = '嗨欢迎您咨讯数字人讲解，有什么为您效劳的呢？ 24小时在线为你解答！'
				this.play(`嗨欢迎您咨讯数字人讲解，有什么为您效劳的呢？ 24小时在线为你解答！`)
			}
		},
		//发送
		send() {
			this.recognizedText = this.chatStr
			this.serveMsg(this.chatStr, this.key)
			this.chatStr = ''
			this.serverStr = ''
		},
		// 选择按钮
		Listitem(e) {
			this.tts.stop()
			this.recognizedText = ''
			this.chatStr = ''
			this.serverStr = ''
			this.recognizedText = e.question
			this.serveMsg(e.question, this.key)
		},
		// 点击想问的问题
		wen(res) {
			this.recognizedText = ''
			this.chatStr = ''
			this.serverStr = ''
			this.recognizedText = res
			this.serveMsg(res, this.key)
		},
		//跳转
		link_href(url) {
			window.open(url)
		},
		open_maxImg() {
			this.pupImageShow = !this.pupImageShow
		},
		//删除del
		del() {
			this.recognizedText = ''
			this.chatStr = ''
			this.serverStr = ''
		},
		// 选择问题之后
		question_fun(res) {
			this.recognizedText = res.question
			this.serveMsg(res.question, this.key)
			//this.list_qyestion(res)
		},
		//服务端返回的问题
		serveMsg(Text, key) {

			clearTimeout(this.timer);
			this.showMessage = false;
			this.startTimer();


			this.question_num(Text)
			this.date2 = new Date()
			this.postRequest(this.constant.loadurl.get_answer, this.qs.stringify({
				question: Text,
				key: key
			})).then((res) => {
				// 请求成功的处理逻辑
				this.serverStr = res.data.data
				if (res.data.code == '0' && Array.isArray(res.data.data) != true) {
					this.state = '0'
					if (res.data.data.type == 0) {
						this.play(res.data.data.answer)
					}
				} else if (res.data.data.length > 0 && Array.isArray(res.data.data)) {
					this.state = '0'
					let msg = res.data.data.map((item, index) => {
						return item.question
					})
					this.play(`你是否想要了解以下问题：${msg.toString()}`)
				} else if (res.data.code == '101' || res.data.code == '102') {
					this.state = '1'
					this.play(res.data.data)
				} else {
					this.play(res.data.msg || res.data.message)
					this.serverStr = res.data.msg || res.data.message
				}
			});
		},

		// 猜你想问
		answer_like() {
			this.postRequest(this.constant.loadurl.get_answer_like, this.qs.stringify({
				key: this.key
			})).then(res => {
				this.likeList = res.data.data
			})
		},
		// 登录之后 模糊问题需走这个接口
		list_qfyestion(res) {
			// get_answer_info?id=
			this.postRequest(this.constant.loadurl.get_answer_info, this.qs.stringify({
				id: res.id,
				key: this.key
			})).then(res => {
				if (res.data.code == 0) {
					this.serverStr = res.data.data
					this.play(res.data.data)
				}
			})
		},
		// 记录问题次数
		question_num(q_num) {
			this.postRequest(this.constant.loadurl.set_answer, this.qs.stringify({
				question: q_num
			})).then((res) => {
				console.log('处理成功')
				// 请求成功的处理逻辑
			});
		},
		//logo和公司名
		logoName(i) {
			this.postRequest(this.constant.loadurl.get_application_setting, this.qs.stringify({
				key: i
			})).then((res) => {
				if (res.data.code == 0) {
					this.gongsi = res.data.data
					if (res.data.data.questionBank) {
						this.qylist = res.data.data.questionBank
					}
					if (res.data.data.digital_background && this.key) {
						this.bgtest = this.gongsi.digital_background.background_img
					} else {
						this.bgtest = 'https://chatoss.zhyell.com/2023-06-27_1687849572_649a8a641e1b6.png'
					}
				}
			})
		},
		send_leave() {
			this.postRequest(this.constant.loadurl.set_leave_message, this.qs.stringify({
				name: this.formLabelAlign.name,
				phone: this.formLabelAlign.region,
				text: this.formLabelAlign.text,
				key: this.$route.query.key
			})).then(res => {
				if (res.data.code == 0) {
					this.$notify({
						title: '提交成功',
						message: res.data.message,
						type: 'success'
					});
					this.lyShow = false
					this.formLabelAlign.name = '';
					this.formLabelAlign.region = '';
					this.formLabelAlign.text = '';
				} else {
					this.$notify.error({
						title: '错误',
						message: res.data.message
					});
				}
			})

		},
		goPageZc() {
			this.$router.push({
				name: "zc"

			})
		},
		goPageXy() {
			this.$router.push({
				name: "xy"
			})
		},
		liuyan() {
			console.log(111);
			this.lyShow = true;
		},
		Clear_screen() {
			this.tts.stop()
			this.recognizedText = ''
			this.chatStr = ''
			this.serverStr = ''
			this.result = ''
		},
		//语音识别
		play(txt) {
			var voiceR = 'xiaoyan'
			if (this.gongsi.voice) {
				voiceR = this.gongsi.voice.voice_name
			}
			this.tts.setParams({
				text: txt, // 文本
				speed: 50, // 音量
				voice: 50, // 语速
				rdn: '2',
				voiceName: voiceR // 
			});
			this.tts.start()
		},
		startTimer() {
			this.lastClickTime = Date.now();
			this.timer = setTimeout(() => {
				this.showMessage = true;
			}, 5 * 60 * 1000); // 5分钟
		},
		// 语音听写
		recordReady() {
			console.info('按钮就绪!')
		},
		recordStart() {
			console.info('录音开始')
		},
		showResult(text) {
			console.info('收到识别结果：', text)

		},
		recordStop(text) {
			console.info('录音结束')
		},
		recordNoResult() {
			console.info('没有录到什么，请重试')
		},
		recordComplete(text) {
			console.info('识别完成! 最终结果：', text)
			this.serveMsg(text, this.key)
		},
		recordFailed(error) {
			console.info('识别失败，错误栈：', error)
		},


	}
}
</script>

<style lang="scss">
html,
body {
	margin: 0;
	padding: 0;
}

.content_tuqiang {
	height: 100vh;
	width: 100%;
	// background-image: url('../../static/imgs/tuqiang.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	position: relative;
	overflow: hidden;
}

.pup_image {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 20000000;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba($color: #0c0c0c, $alpha: 0.5);
}

.content {
	height: 100vh;
	width: 100%;
	// background: url('../../static/imgs/pcbj.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	position: relative;
}

/*
窗口位置:1:中下 2:右下 3:居中 4:右中
*/
.boxOne {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
}

.boxTwo {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
}

.boxThree {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.boxFour {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

/*
窗口大小:1窗口 2全屏
*/
.sizeOne {
	height: 80vh;
	width: 90%;
}

.sizeTwo {
	height: 100vh;
	width: 100%;

}

.logo_name {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;

	img {
		margin-right: .3rem;
		border-radius: 50%;
	}
}

.quest {
	position: absolute;
	top: 40px;
	left: 20px;
	color: #ffffff;
	padding: 8rem 0px 0rem 8rem;

	.bg_list {
		padding: 20px 50px;
		background-color: rgba(26, 26, 36, 0.5);
		border-radius: 20px;

		.biaoti {
			font-size: 25px;
		}
	}

	.box1 {
		display: flex;
		align-items: center;
		margin: 1.25rem 0rem;
		font-size: .9rem;
		cursor: pointer;

		.icon_D {
			height: 0.3rem;
			width: 0.3rem;
			border-radius: 50%;
			background-color: #fffFFF;
			margin-right: 20px;
		}

		.daan {
			font-size: 15px;
			// font-weight: bold;
		}
	}
}

.rightBox {
	position: absolute;
	right: 6.25rem;
	top: 8rem;
	height: 22rem;
	width: 26.25rem;
	background-color: rgba($color: #1A1A24, $alpha: .5);
	border-radius: 20px;
	opacity: 0.8;
	color: #ffffff;

	.plant {
		margin-left: 20px;
		margin-top: 20px;

		.date {
			margin: 10px 0px;

			font-size: 14px;
		}

		.wenti {
			padding: 10px;
			font-size: 15px;
			color: #fffFFF;
			width: 275px;
			background: rgba($color: #ffffff, $alpha: .1);
			border-radius: 0px 10px 10px 10px;
		}
	}

	.serve {
		position: absolute;
		right: 20px;

		.date {
			margin: 10px 0px;
			font-size: 14px;
		}

		.huifu {
			padding: 10px;
			text-align: justify;
			font-size: 15px;
			color: #FFFFFF;
			width: 275px;
			max-height: 180px;
			background: rgba($color: #ffffff, $alpha: .1);
			border-radius: 10px 0px 10px 10px;
			overflow-y: auto;

			.imgMsg {
				height: 200px;
				width: 200px;
				border-radius: 0;
			}
		}
	}
}

.title {
	white-space: nowrap;
}

.wentiBox {
	display: flex;
	flex-direction: column;
	white-space: pre-wrap;
}

.pupBox {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 200;
	background-color: rgba(24, 24, 24, 0.5);
	display: flex;
	justify-content: center;
	top: 0;

	.video_box {
		height: 25rem;
		width: 21.875rem;

		video {
			margin-top: 100px;
			height: 100%;
			width: 100%;
			border-radius: 10px;
			object-fit: fill;
		}

		.icon_close {
			position: absolute;
			z-index: 2001;
			left: 50%;
			transform: translateX(-50%);
			height: 20px;
			width: 20px;
			border-radius: 50%;
			color: #fffFFF;
			text-align: center;
			border: 1px solid #ffffff;
		}
	}

}

.chat_user {
	bottom: 20rem;
	right: 1.25rem;
	padding: .3125rem;
	position: absolute;
	background-color: rgba($color: #ffffff, $alpha: 0.5);
	border-radius: .4375rem .4375rem 0 .4375rem;
}

.serve_text {
	max-height: 340px;
	max-width: 60%;
	bottom: 15rem;
	left: 1.25rem;
	padding: .3125rem;
	position: absolute;
	font-size: 16px;
	background-color: rgba($color: #FFFFFF, $alpha: 0.5);
	border-radius: .4375rem .4375rem .4375rem 0rem;
	text-align: left;
	overflow-y: scroll;
	cursor: pointer;
}

.active {
	color: rgb(76, 114, 114);
	text-decoration-line: underline;
}

.msg {
	cursor: pointer;
	font-size: 1rem;

	.names {
		white-space: nowrap;

	}
}

// 这是底部
.container {
	min-width: 45%;
	position: absolute;
	bottom: 5%;
	left: 50%;
	transform: translateX(-50%);
	background-image: url('../../static/imgs/kuang.png');
	background-size: 100% 100%;
	background-position: left;
	background-repeat: no-repeat;

	.button_menu {
		position: relative;
		top: -1.25em;

		#app {
			display: flex;
			justify-content: center;
			text-align: center;
		}
	}

	.List {
		width: 95%;
		display: flex;
		margin: .9375rem auto;
		justify-content: space-around;
		// border-bottom: 0.1px solid #ffffff;
		padding-bottom: 10px;

		div {
			color: #333333;
			font-size: .875rem;
			padding: .35rem 16px;
			margin: 0 .3125rem;
			border-radius: 1.625rem;
			background: #ffffff;
			white-space: nowrap;

		}

	}

	.bottom_border {
		height: 0.01rem;
		width: 93%;
		// padding:0px 20px;
		background: #ffffff;
		opacity: 0.3;
		margin: 0 auto;
		margin-bottom: .625rem;
	}

	.edit_text {
		width: 93%;
		height: 3.125rem;
		overflow: hidden;
		margin: 0 auto;
		color: #fffFFF;

		textarea {
			width: 100%;
			height: 100%;
			white-space: pre-wrap;
			font-size: .875rem;
			resize: none;
			color: #FFFFFF !important;
			/* 重置所有边框样式 */
			border: none;
			outline: none;
			padding: 0;
			background: none;

		}
	}

	.send_btn {
		display: flex;
		justify-content: flex-end;
		margin: .625rem;

		.Ly,
		.pcSend,
		.clear {
			padding: 8px 23px;
		}

		.clear {
			position: absolute;
			left: 3.2%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-right: 20px;
			border: 1px solid #3E7CEB;
			border-radius: 28px;
			margin-bottom: .625rem;
			font-size: .875rem;
			color: #3E7FEB;

			img {
				margin-right: 5px;
			}
		}

		.Ly {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 20px;
			color: #3E7FEB;
			border: 1px solid #3E7CEB;
			border-radius: 28px;
			margin-bottom: .625rem;
			font-size: .875rem;
		}

		.pcSend {
			display: flex;
			justify-content: center;
			align-items: center;

			color: #ffffff;
			background-color: #3E7FEB !important;
			margin-right: 1.1rem;
			margin-bottom: .625rem;
			font-size: .875rem;
			border-radius: 30px;
		}
	}

	.voice-input-button-wrapper {
		width: 3rem;
		height: 3rem;
		background: #FFFFFF;
		border-radius: 50%;
	}
}

.PYsxY {
	display: flex;
	position: absolute;
	bottom: 10px;
	width: 100%;
	color: #5B5B5B;
	font-size: 14px;
	justify-content: center;

	.line_two {
		display: flex;
	}
}

/* 定义滚动条样式 */
::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	background-color: rgba(240, 240, 240, 1);
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
	border-radius: 10px;
	background-color: rgba(240, 240, 240, .5);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 0px rgba(116, 110, 110, 0.5);
	background-color: rgba(116, 110, 110, .5);
}

.liuYan_model {
	width: 100%;
	height: 100vh;
	position: absolute;
	background-color: rgba(0, 0, 0, .8);
	z-index: 321000;

	.form_liuyan {
		position: absolute;
		width: 37.5rem;
		height: 35.3125rem;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

	}

	.titles {
		display: flex;
		width: 560px;
		height: 64px;
		align-items: center;
		background: #F0F0F0;
		justify-content: space-between;
		padding: 0px 20px;

		img {
			height: 14px;
			width: 14px;
		}
	}

	.form_box {
		background-color: #ffffff;
		padding: 10px 20px;
	}

	.btnClik {
		display: flex;
		justify-content: flex-end;

		.Back,
		.Send {
			width: 120px;
			height: 44px;
			line-height: 44px;
			text-align: center;
		}

		.Back {

			background: #F0F0F0;
			border-radius: 4px;
			color: #000;
			margin-right: 1.25rem;
		}

		.Send {

			color: #ffffff;
			background: #3E7FEB;
			border-radius: 4px;
		}
	}

	.el-input__inner {
		color: #999999 !important;
		border-radius: 4px !important;
		background: #F0F0F0 !important;
	}

	.el-textarea__inner {
		color: #999999 !important;
		border-radius: 4px !important;
		background: #F0F0F0 !important;
	}
}</style> 