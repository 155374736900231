import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
// 导入一级路由
import new_digtai from '@/views/new_digtai'
import login from '@/views/login'
import index from '@/views/index'
import yhxy from '@/views/yhxy'
import ysxy from '@/views/ysxy'
import renwu from "../components/renwu"
import digtai from '@/views/digtai'
import Ly from '@/views/LiuYan'
import chatH5 from '@/views/chatH5'
Vue.use(VueRouter)
 
// 解决导航重复
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [{
        path: '/',
        redirect: '/digtai'
    },
    {
        path: '/new_digtai',
        name: 'new_digtai',
        component: new_digtai,
    },
	{
		path:"/digtai",
		name:'digtai',
		component:digtai
	},
	{
		path:'/chatH5',
		name:'chatH5',
		component:chatH5
	},
	{
		path:'/Ly',
		name:'Ly',
		component:Ly
	},
    {
        path: '/login',
        name: 'login',
        component: login
    },
    {
        path: '/index',
        name: 'index',
        component: index
    },
    {
        path: '/yhxy',
        name: 'zc',
        component: yhxy
    },
    {
        path: '/ysxy',
        name: 'xy',
        component: ysxy
    },{
		path:'/renwu',
		name:'renwu',
		component:renwu
	}
]
 
 
const router = new VueRouter({
	// mode:'history',
    routes
})
 
 
 
export default router
//路由拦截器,全局前置守卫
router.beforeEach((to, from, next) => {
	if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
		if (localStorage.getItem('token')) { // 通过localStorage获取当前的token是否存在
			next();
		} else {
			next({
				path: '/new_digtai',
				// query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
			})
		}
	} else {
		next();
	}
	/*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
	if (to.fullPath == "/index") {
		if (localStorage.getItem('token')) {
			if (from.fullPath == "/") {
				next({
					path: "/new_digtai", //跳首页
				});
			} else {
				next({
					path: from.fullPath
				});
			}

		} else {
			next();
		}
	}
})
// 请求超时时间
axios.defaults.timeout = 120000;
// http请求拦截
axios.interceptors.request.use(config => {
	// console.log("请求拦截器", config);
	//如果存在token，请求携带这个token
	// windows.sessionStorage.getItem()
	if (localStorage.getItem("token")) {
		config.headers['Authorization'] ="Bearer " + localStorage.getItem("token");
	}
	return config;
}, error => {
	// 对请求错误做些什么
	Vue.prototype.$message.error('请求超时')
	return Promise.reject(error)
});
// http响应拦截
axios.interceptors.response.use(response => {
	// console.log("响应拦截器", response);
	// res是响应的结果
	switch (response.data.code) {
		case 1001:
			// 登录失效
			// 响应成功的拦截
			Vue.prototype.$message.error(response.data.msg)
			localStorage.removeItem('Authorization')
			router.push({name:'index'})
			break
		case 1002:
			// 强制下线
			// 响应成功的拦截
			Vue.prototype.$message.error(response.data.msg)
			localStorage.removeItem('Authorization')
			router.push({name:'index'})
			break
		case 404:
			if (response.data.msg !== null) {
				Vue.prototype.$message.error(response.data.msg)
			} else {
				Vue.prototype.$message.error('未知错误')
			}
			break
		case 500:
			// 错误
			if (response.data.msg !== null) {
				Vue.prototype.$message.error(response.data.msg)
			} else {
				Vue.prototype.$message.error('未知错误')
			}
			break
		default:
			return response
	}
	return response
}, error => {
	if (error.response.data.msg) {
		Vue.prototype.$message.error(err.response.data.msg)
		return Promise.reject(error.response.data.msg) // 返回接口返回的错误信息
	} else {
		// 返回状态码不为200时候的错误处理
		Vue.prototype.$message.error(error.toString())
		return Promise.resolve(error)
	}
});
