<template>
    <div class="continuer">
        <div class="back" @click="back">
            <i class="el-icon-arrow-left"></i>
            <div>返回</div>
        </div>
        <div class="box">
            <div class="header">
                <img src="../assets/image/icon.png" alt="">
                <div>发送留言信息请填写手机号！</div>
                <div>我们会尽快与您联系~</div>
            </div>
            <div class="form_box">
                <el-form :label-position="labelPosition" class="demo-ruleForm" :rules="rules" label-width="80px"
                    :model="formLabelAlign" :status-icon='true'>
                    <el-form-item label="姓名:" prop="name">
                        <el-input v-model="formLabelAlign.name"></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式:" prop="region">
                        <el-input v-model="formLabelAlign.region" type="number"
                            oninput="if(value.length > 11) value=value.slice(0, 11)"></el-input>
                    </el-form-item>
                    <el-form-item label="留言:" prop="text">
                        <el-input v-model="formLabelAlign.text" type="textarea" :rows="8"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="btn" @click="send_leave">
            <div>提交留言</div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            labelPosition: 'top',
            formLabelAlign: {
                name: '',
                region: '',
                text: ''
            },
            maxLength: 11,
            rules: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
                ],
                region: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { min: 11, max: 11, message: '长度最长在11个字符', trigger: 'blur' }
                ],
                text:[
                    { required: true, message: '请输入留言', trigger: 'blur'}
                ]
            }
        }
    },
    methods: {
        send_leave(){
			this.postRequest(this.constant.loadurl.set_leave_message, this.qs.stringify({
                name:this.formLabelAlign.name,
                phone:this.formLabelAlign.region,
                text:this.formLabelAlign.text,
                key:this.$route.query.key
            })).then(res=>{
                if(res.data.code == 0){
                    this.$notify({
                    title: '提交成功',
                    message:res.data.message,
                    type: 'success'
                    });
                    this.formLabelAlign.name = '';
                    this.formLabelAlign.region = '';
                    this.formLabelAlign.text = '';
                }else{
                    this.$notify.error({
                        title: '错误',
                        message: res.data.message
                    });
                }
            })
            
        },
        back() {
            this.$router.go(-1)
        }
    }
}

</script>
<style lang="scss">
.continuer {
    height: 100vh;
    background-color: #080808;
}

.back {
    display: flex;
    color: #ffffff;
    padding: 15px 10px;
    align-items: center;
    background-color: #212121;

    i {
        font-size: 30px;
    }

    .div {
        margin-left: 20px;
    }
}

.box {
    width: 99%;
    margin: 20px auto;
    background-color: #212121;
    border-radius: 5px;
    .header {
        padding: 15px 0px;
        text-align: center;

        img {
            height: 100px;
            width: 100px;
        }

        div {
            padding-top: 5px;
            color: #888888;
            font-size: 13px;
        }
    }
}

.btn {
    position: fixed;
    bottom:0px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin: auto;
    color: #ffffff;
    text-align: center;
    background-color: #212121;
    padding: 8px 0px;
    div {
        width: 99%;
        margin: 0 auto;
        padding: 15px 0px;
        background: linear-gradient(90deg, #3C65EA, #42ADEC);
        border-radius: 4px;
    }
}
.form_box{
    padding:0px 15px 5px 15px;
}
.el-input__inner{
    color: #ffffff !important;
    border:none !important;
    background: #333333 !important;
}
.el-textarea__inner{
    color: #ffffff !important;
    border:none !important;
    background: #333333 !important;
}
</style>