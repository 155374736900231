<!-- 手机端-->
<template>
	<div class="content" :style="{ backgroundImage: 'url(' + bgtest + ')' }" :class="[gongsi.window_position == 1 ? 'boxOne' : '',
	gongsi.window_position == 2 ? 'boxTwo' : '',
	gongsi.window_position == 3 ? 'boxThree' : '',
	gongsi.window_position == 4 ? 'boxFour' : '',
	gongsi.window_size == 1 ? 'sizeOne' : '',
	gongsi.window_size == 2 ? 'sizeTwo' : '',
	]">
		<div class="pup_image" v-if="pupImageShow">
			<img :src="serverStr.answer" alt="" srcset="" @click="open_maxImg" />
		</div>
		<div class="logo_name" v-if="gongsi.logo && gongsi.enterprise_name">
			<img :src="gongsi.logo" alt="" width="40" height="40" class="src">
			<div>{{ gongsi.enterprise_name }}</div>
		</div>
		<renwu  v-if="gongsi" :environment="gongsi.environment" :seat="gongsi.seat" :parallel="gongsi.parallel"
			@handleClick="handleClick1"></renwu> 
		<div class="pupBox" v-if="serverStr.type == 1">
			<div class="video_box">
				<video :src="serverStr.answer || mp4Url" controls autoplay></video>
				<div class="icon_close" @click="del" style="">x</div>
			</div>
		</div>

		<div class="container_a">
            <div>
                <div class='chat_users' v-if='recognizedText || result'>
                    <div>
                        {{ recognizedText || result }}
                    </div>
                </div>
                <div class="huifu" v-if="state == '1'">
                    <img src="../../static/imgs/touxiang.png" alt="">
                    <div class='serve_text_a'>{{ serverStr }}</div>
                </div>
                <div class="huifu" v-if="serverStr.type == 0">
                    <img src="../../static/imgs/touxiang.png" alt="">
                    <div class='serve_text_a'>{{ serverStr.answer }}</div>
                </div>

                <div class="huifu" v-if="serverStr.type == 2">
                    <img src="../../static/imgs/touxiang.png" alt="">
                    <div class='serve_text_a active' @click="link_href(serverStr.answer)">
                        点我跳转
                    </div>
                </div>
                <div class='huifu ' v-if="serverStr.type == 3">
                    <img src="../../static/imgs/touxiang.png" alt="">
                    <div class='serve_text_a ' @click="open_maxImg(serverStr.answer)">
                        <img :src="serverStr.answer" alt="" srcset="">
                    </div>
                </div>
                <div class="huifu" v-if="serverStr.length > 0 && this.state == 0">
                    <img src="../../static/imgs/touxiang.png" alt="">
                    <div class='serve_text_a'>
                        <div class="title">你是否想要了解以下问题：</div>
                        <div v-for="(item, index) in serverStr" @click="question_fun(item)" class="msg">
                            <div>{{ item.question }}</div>
                        </div>
                    </div>
                </div>
            </div>
			<div class="dibu">
				<div class='button_menu'>
					<div id="apps">
						<div class="voice-input-button-wrapper">
							<voice-input-button appId="5e7318f9" apiKey="7f067847197d323ac97da9fa173d461a"
								apiSecret="267eb2c7702ddabc3c419bd6931c7f04" v-model="result" @record="showResult"
								@record-start="recordStart" @record-stop="recordStop" @record-blank="recordNoResult"
								@record-failed="recordFailed" @record-ready="recordReady" @record-complete="recordComplete"
								interactiveMode="press" color="#3f8ceb" tipPosition="top" tipTextColor="#ffffff"
								:vad_eos="300">
								<template slot="no-speak">没听清您说的什么</template>
								<template slot="recording">好，请讲</template>
								<template slot="wait">请稍后</template>
							</voice-input-button>

						</div>
					</div>
				</div>
				<div class="bottom_border"></div>

				<div class='edit_text'>
					<textarea placeholder="您想咨询什么？" v-model="chatStr">  </textarea>
				</div>
				<div class='send_btn'>
					<div class="clear" @click="Clear_screen">清屏</div>
					<div class="Ly" @click="liuyan">留言</div>
					<div @click="send" class="sendA">发送</div>
				</div>
			</div>
			<div class="YsxY">
				<div>该产品由嗨媒体AI支持，内容仅供参考，</div>
				<div class="line_two">
					不代表本公司的态度或观点 请遵守<div class="zc" @click="goPageZc" >《隐私政策》</div>和<div class="xy" @click="goPageXy">《隐私协议》</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axios from "axios"
import console, { log } from "console"
import { isVideoURL } from '../utils/isVideoURL.js'
import voiceInputButton from 'voice-input-button2'
import ttsRecorder from "../assets/audio"
import renwu from "../components/renwu.vue"
import { type } from "os"
// const tts = new ttsRecorder() // 语音合成
export default {
	components: {
		voiceInputButton,
		renwu
	},
	data() {
		return {
			title: 'Hello',
			qylist: [{question:'公司简介'},{question:'企业展厅'},{question:'企业视频'}],
			list: [{question:'公司简介'},{question:'企业展厅'},{question:'企业视频'}],
			result: '', // 转换完的文字
			recognizedText: '',
			chatStr: '', // 客户端问题
			serverStr: '', // 服务端问题
			state: '3',//区分百度1或者自定0
			mp4Show: false,
			mp4Url: '',
			key: '', // 不同企业 对应不同的标识
			gongsi: '',
			timer: null,
			tts: new ttsRecorder(),
			pupImageShow: false,
			bgtest: 'https://chatoss.zhyell.com/2023-06-27_1687849572_649a8a641e1b6.png',
			timer: null,
			showMessage: false
		}
	},
	// 监听用户多久操作
	watch: {
		showMessage: {
			handler(n) {
				if(n == true){
					var t = this
					t.play('主人有什么需要帮忙的吗？你可以说，讲个故事，来一张图片，说个笑话')
				}
			},
			deep: true
		},
	},
	onLoad() {
		document.body.style.overflow = 'hidden';
	},

	mounted() {
		if (this.$route.query.key) {
			this.key = this.$route.query.key;
		} else {
			this.key = ''
		}
		this.startTimer()
		this.logoName(this.key)
		this.handleClick1()
	},

	methods: {
		// 模型
		handleClick1(res) {
			let that = this
			that.result = `嗨,欢迎您咨讯数字人讲解，有什么为您效劳的呢？ 24小时在线为你解答！`
			if (res == true) {
				that.play(that.result)
			}
		},
		//发送
		send() {
			this.recognizedText = this.chatStr
			this.serveMsg(this.chatStr, this.key)
			this.chatStr = ''
			this.serverStr = ' '
		},
	
		//跳转
		link_href(url) {
			window.open(url)
		},
		open_maxImg() {
			this.pupImageShow = !this.pupImageShow
		},
		//删除del
		del() {
			this.recognizedText = ''
			this.chatStr = ''
			this.serverStr = ''
		},
		// 选择问题之后
		question_fun(res) {
			this.recognizedText = res.question
			this.serveMsg(res.question, this.key)
		},
		toggleFullscreen() {
			this.isFullscreen = !this.isFullscreen;
		},
		//服务端返回的问题
		serveMsg(Text, key) {
			clearTimeout(this.timer);
			this.showMessage = false;
			this.startTimer();

			this.question_num(Text)
			this.postRequest(this.constant.loadurl.get_answer, this.qs.stringify({
				question: Text,
				key: key
			})).then((res) => {
				// 请求成功的处理逻辑
				this.serverStr = res.data.data
				if (res.data.code == '0' && Array.isArray(res.data.data) != true) {
					this.state = '0'
					if (res.data.data.type == 0) {
						this.play(res.data.data.answer)
					}
				} else if (res.data.data.length > 0 && Array.isArray(res.data.data)) {
					this.state = '0'
					let msg = res.data.data.map((item, index) => {
						return item.question
					})
					this.play(`你是否想要了解以下问题：${msg.toString()}`)
				} else if (res.data.code == '101' || res.data.code == '102') {
					this.state = '1'
					this.play(res.data.data)
				} else {
					this.play(res.data.msg || res.data.message)
					this.serverStr = res.data.msg || res.data.message
				}
			});
		},
		// 登录之后 模糊问题需走这个接口
		list_qfyestion(res) {
			// get_answer_info?id=
			this.postRequest(this.constant.loadurl.get_answer_info, this.qs.stringify({
				id: res.id,
				key: this.key
			})).then(res => {
				if (res.data.code == 0) {
					this.serverStr = res.data.data
					this.play(res.data.data)
				}
			})
		},
		// 记录问题次数
		question_num(q_num) {
			this.postRequest(this.constant.loadurl.set_answer, this.qs.stringify({
				question: q_num
			})).then((res) => {
				console.log('处理成功')
				// 请求成功的处理逻辑
			});
		},
		//logo和公司名
		logoName(i) {
			this.postRequest(this.constant.loadurl.get_application_setting, this.qs.stringify({
				key: i
			})).then((res) => {
				if (res.data.code == 0) {
					this.gongsi = res.data.data
					if (res.data.data.questionBank) {
						this.qylist = res.data.data.questionBank
					}else{
						this.qylist = this.list;
					}
					 
					if (res.data.data.digital_background && this.key) {
						this.bgtest = this.gongsi.digital_background.background_img
					} else {
						this.bgtest = 'https://chatoss.zhyell.com/2023-06-27_1687849572_649a8a641e1b6.png'

					}
				}
			})
		},
		goPageZc(){
			this.$router.push({
				name: "zc"

			})
		},
		goPageXy(){
			this.$router.push({
				name: "xy"
			})
		},
		Clear_screen(){
			this.tts.stop()
			this.recognizedText = ''
			this.chatStr = ''
			this.serverStr = ''
			this.result = ''
		},
		liuyan(){
			this.$router.push('/Ly?key='+ this.$route.query.key)	
		},
		startTimer() {
			this.lastClickTime = Date.now();
			this.timer = setTimeout(() => {
				this.showMessage = true;
			}, 5 * 60 * 1000); // 5分钟
		},
		//语音识别
		play(txt) {
			var voiceR = 'xiaoyan'
			if(this.gongsi.voice){
				voiceR = this.gongsi.voice.voice_name
			}
			this.tts.setParams({
				text: txt, // 文本
				speed: 50, // 音量
				voice: 50, // 语速
				rdn: '2',
				voiceName:voiceR  // 
			});
			this.tts.start()
		},
		// 语音听写
		recordReady() {
			console.info('按钮就绪!')
		},
		recordStart() {
			console.info('录音开始')
		},
		showResult(text) {
			console.info('收到识别结果：', text)

		},
		recordStop(text) {
			console.info('录音结束')
		},
		recordNoResult() {
			console.info('没有录到什么，请重试')
		},
		recordComplete(text) {
			console.info('识别完成! 最终结果：', text)
			this.serveMsg(text, this.key)
		},
		recordFailed(error) {
			console.info('识别失败，错误栈：', error)
		},
	}
}
</script>

<style lang="scss">
html,
body {
	margin: 0;
	padding: 0;
	// overflow: hidden;
}

/*
不全屏 ：height: 80vh;width: 90%;
*/
.content_tuqiang {
	height: 100vh;
	width: 100%;
	// background-image: url('../../static/imgs/tuqiang.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	position: relative;
	overflow: hidden;
}

.pup_image {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 20000000;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba($color: #0c0c0c, $alpha: 0.5);

}

.content {
	height: 100vh;
	width: 100%;
	// background: url('../../static/imgs/bj.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	position: relative;
	overflow: hidden;
}

/*
窗口位置:1:中下 2:右下 3:居中 4:右中
*/
.boxOne {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
}

.boxTwo {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
}

.boxThree {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.boxFour {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

/*
窗口大小:1窗口 2全屏
*/
.sizeOne {
	height: 80vh;
	width: 90%;
}

.sizeTwo {
	height: 100vh;
	width: 100%;

}

.logo_name {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	padding-top: 1.25rem;
	font-size: .875rem;

	img {
		margin-right: .3rem;
		border-radius: 50%;
	}
}

.login {
	margin-right: 20px;
	padding-top: 20px;
	text-align: right;
	color: #ffffff;
}

.pupBox {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 200;
	background-color: rgba(24, 24, 24, 0.5);
	display: flex;
	justify-content: center;
	top: 0;

	.video_box {
		height: 400px;
		width: 350px;

		video {
			margin-top: 6.25rem;
			height: 100%;
			width: 100%;
			border-radius: .625rem;
			object-fit: fill;
		}

		.icon_close {
			position: absolute;
			z-index: 2001;
			left: 50%;
			transform: translateX(-50%);
			height: 1.25rem;
			width: 1.25rem;
			border-radius: 50%;
			color: #fffFFF;
			border: .0625rem solid #ffffff;
			text-align: center;
		}
	}

}

.chat_users {
	display: flex;
	// position: absolute;
	color: #FFFFFF;
	justify-content: flex-end;
	text-align: right;

	div {
		max-height: 12.5rem;
		font-size: .875rem;
		padding: 6.4px 11.2px;
		background: #41A6EC;
		border-radius: 1.8125rem 0rem 2rem 1.8125rem;
		overflow: auto;
		text-align: left ;
	}
}

.huifu {
	display: flex;
	margin-top: .625rem;
	margin-bottom: 1.25rem;

	.serve_text_a {
		max-height: 4.5rem;
		max-width: 17rem;
		padding: 8px;
		font-size: .875rem;
		background-color: rgba($color: #0c0c0c, $alpha: 0.8);
		border-radius: 0px 1rem 1rem 1rem;
		text-align: justify;
		overflow-y: auto;
		color: #42A9EC;
		margin-left: 15px;

		img {
			height: 200px !important;
			width: 200px !important;
		}
	}

	img {
		height: 2.5625rem;
		width: 2.5625rem;
		border-radius: 50%;
	}
}

.active {
	color: rgb(76, 114, 114);
	text-decoration-line: underline;
}

.msg {
	font-size: 16px;

	div {
		font-size: 14px;

	}
}

// 这是底部
.container_a {
	width: 95% !important;
	position: absolute;
	bottom: 1%;
	left: 50%;
	transform: translateX(-50%);

	.dibu {
		width: 100%;
		background-size: 100% 100%;
		background-position: left;
		background-repeat: no-repeat;
		background-image: url('../../static/imgs/bj-k.png');
	}
	.YsxY{
		font-size:12px;
		color:#5B5B5B;
		text-align: center;
		.line_two{
			display:flex;
			justify-content: center;
		}
	}
	.button_menu {
		position: relative;
		top: -19.2px;

		#apps {
			display: flex;
			justify-content: center;
			text-align: center;
			// margin-top: 8px;
		}
	}

	.List {
		width: 95%;
		display: flex;
		// padding-bottom: 6.8px;
		margin: 5px auto;
		justify-content: space-between;
		flex-wrap: wrap;

		div {
			color: #333333;
			font-size: 14px;
			padding: 5.6px 1rem;
			margin: 0 3px;
			border-radius: 26px;
			background: #CECECE;
			white-space: nowrap;
			margin-top: .625rem;
		}

	}

	.bottom_border {
		height: 1px;
		width: 90%;
		background: #5C5D5F;
		opacity: 0.2;
		margin: 0 auto;
		margin-bottom: 10px;
	}

	.edit_text {
		width: 90%;
		height: 50px;
		overflow: hidden;
		margin: 0 auto;
		// padding: 0px 7px;

		textarea {
			width: 100%;
			height: 100%;
			white-space: pre-wrap;
			font-size: 14px;
			resize: none;
			color: #0c0c0c !important;
			/* 重置所有边框样式 */
			border: none;
			outline: none;
			padding: 0;
			background: none;

		}
	}

	.send_btn {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.clear{
			position: absolute;
			left: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 20px;
			color:rgb(187, 183, 183);
			border:1px solid #3E7CEB;
			border-radius: 28px;
			margin-bottom: .625rem;
			font-size: .875rem; 
		}
		.Ly,.sendA,.clear{
			padding: 8px 23px;
		}
		.Ly{
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 20px;
			color:#3E7FEB;
			border:1px solid #3E7CEB;
			border-radius: 28px;
			margin-bottom: .625rem;
			font-size: .875rem;
		}
		.sendA {
			display: flex;
			justify-content: center;
			align-items: center;
			
			color: #ffffff;
			background-color: #3E7FEB !important;
			margin-right: 5px;
			margin-bottom: .625rem;
			font-size: .875rem;
			border-radius: 30px;
		}
	}

	.voice-input-button-wrapper {
		width: 64px;
		height: 64px;
		background-color: #fffFFF;
		border-radius: 50%;
	}
}

/* 定义滚动条样式 */
::-webkit-scrollbar {
	width: .125rem;
	height: .125rem;
	background-color: rgba(240, 240, 240, 1);
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 0rem rgba(240, 240, 240, .5);
	border-radius: .625rem;
	background-color: rgba(240, 240, 240, .5);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
	border-radius: .625rem;
	box-shadow: inset 0 0 0rem rgba(116, 110, 110, 0.5);
	background-color: rgba(116, 110, 110, .5);
}
</style> 
