<template>
	<div class="ysxy">
		<div class="back" @click="back">
			<i class="el-icon-arrow-left"></i>
			返回
		</div>
		<div class="paragraph">
			<div style="text-align:center;width:100%;">
				<h1>嗨媒体AI平台隐私政策</h1>
			</div>
			<div>
				欢迎您使用嗨媒体AI平台（以下简称：“本平台”）！我们深知个人信息对您的重要性，您的信赖对我们非常重要，我们将严格遵守法律法规要求采取相应的安全保护措施，致力于保护您的个人信息。基于此，制定本《嗨媒体AI平台隐私政策》（以下简称“本政策”），以帮助您充分了解在您本平台相关服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地作出适当的选择。
				在您使用本平台服务前，请您务必先仔细阅读和理解本政策。除本政策外，在特定场景下，我们还会通过即时告知（含弹窗、页面提示等）、功能更新说明等方式，向您说明对应的信息收集目的、范围及使用方式，这些即时告知及功能更新说明等构成本政策的一部分，并与本政策具有同等效力。本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。如对本政策内容有任何疑问、意见或建议，您可通过本政策文末的联系方式与我们联系。
			</div>
		</div>
		<class class="paragraph">
			<h2>一、适用范围</h2>
			<div>
				本政策适用于嗨媒体AI平台相关产品及服务。我们及关联公司在本平台下向您提供的某些独立产品/功能单独设有隐私政策的，相关产品/功能适用其独立的隐私政策。
			</div>
			<h2>二、信息收集及使用</h2>
			<div>在您使用本平台服务时，我们需要或可能需要收集和使用的您的个人信息包括如下两种：</div>
			<div>1、为实现向您提供本平台的基本功能及履行法律法规规定的义务，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用本平台；</div>
			<div>2、为向您提供增强用户体验的本平台某些附加功能，您可选择单独同意或不同意我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但不会影响您使用本平台基本功能。
请注意：如果您提供的时非本人的个人信息，请您确保已取得相关主体的同意。</div>
			<h3>（一）注册及登录</h3>
			<div>当您申请及使用本平台时，需要登录您的账号。如新注册账号，请发送邮件hbsxxxjs@126.com</div>
			<h3>（二）信息收集</h3>
			<div>在您使用本平台服务的过程中，我们会收集以下您的信息：
当您获得使用本平台服务的资格后，AI数字人将为您提供聊天互动，生成图像等服务。我们会收集收集您所输入的文本信息，以便根据您的文本信息提供文生文的人机交互服务。 此外，在经过去标识化且无法重新识别特定个人的前提下，我们也会使用您输入的内容及模型输出的结果来不断提高AI数字员工的理解能力及输出质量。如您拒绝输入文本信息，您将无法体验本平台完整的服务。如您评价的内容涉及第三方的信息（包括但不限于个人信息），在输入这些信息之前，您需确保您已经取得合法的授权，避免泄露他人个人信息，侵犯他人权利。</div>
			<h3>（三）运营分析</h3>
			<div>我们可能会使用日志数据（包括您的浏览器信息和类型、您提出请求的日期和时间，以及您如何与我们的网站互动）、使用信息（您的位置、例如客户端日期和访问时间、计算机或移动设备的类型、网络信息）、设备信息（包括您正在使用的设备信息、操作系统和浏览器类型）、Cookie的在线分析产品来帮助我们分析您如何使用我们的平台服务，并增强您的用户体验。
我们承诺不会将Cookie用于本政策所述目的之外的任何其他用途。我们使用Cookie主要为了保障本平台服务的安全、高效运转，可以使我们确认您账号的安全状态，排查崩溃、延迟的相关异常情况，帮助您省去重复填写表单、输入内容的步骤和流程。</div>
			<h3>（四）运营分析</h3>
			<div>依法豁免征得您的授权同意的情形
请您理解，在法律法规规定的情形中，根据法律法规及国家标准，我们转移、公开您的个人信息无需征得您的授权同意。</div>
			<h2>三、信息的存储</h2>
			<h3>（一）存储期限</h3>
			<div>我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求。 在超出保留期间后，我们会根据适用法律的要求删除或匿名化处理您的用户信息。</div>
			<h3>（二）存储位置</h3>
			<div>我们在中华人民共和国境内产生或收集的个人信息，存储在中国境内的服务器。除非取得您的单独同意，或为了履行与您订立的合同，或为了遵循法律法规规定的义务，我们不会向境外提供您的任何个人信息。</div>
			<h3>（三）存储安全</h3>
			<div>我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们建立了行业内先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理及技术的维度提升个人信息的安全性。有一套安全的技术保障措施包括三个层面“防火墙层面”、“数据库资源层面”、“业务交互层面”，并不断加强员工对于保护个人信息重要性的认识。
如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损的，我们会及时启动应急预案，采取合理必要的措施，以尽可能降低对您个人的影响。如发生个人信息安全事件，我们还将按照法律法规的要求向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将以短信、电话、推送通知及其他合理渠道告知您，难以逐一告知的，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</div>
			<h2>四、未成年人保护</h2>
			<div>
				在您使用本平台服务的过程中我们推定您具有相应的民事行为能力。如您未满18周岁，我们要求您请您的父母或其他监护人仔细阅读本政策，并在征得您的父母或其他监护人同意的前提下使用我们的服务或向我们提供信息。
			</div>
			<h2>五、政策的更新</h2>
			<div>
				为给您带来更好的服务使用体验，我们持续努力改进我们的技术。我们可能会更新本政策，并通过网页提示或其他适当的方式提醒您更新的内容，以便您及时了解本政策的最新版本。但未经您明确同意，我们不会限制您按照本政策所享有的权利。
			</div>
			<h2>六、联系我们</h2>
			<div>
				如果您对本政策及对您个人信息的处理有任何疑问、意见、建议或投诉，请通过邮箱hbsxxxjs@126.com与我们联系。在一般情况下，我们会在15个工作日内对您的请求予以答复。
如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。
			</div>
		</class>
	</div>
</template>

<script>
export default {
	data() {
		return {

		};
	},
	methods: {
		back() {
			this.$router.go(-1)
		}
	}
}
</script>

<style lang="scss">
.ysxy {
	padding: 20px 30px 0px 30px;
	display: block;
	background-color: #333;

	.back {
		width: 100%;
		text-align: left;
		color: #FFFFFF;
	}

	.paragraph {
		padding: 30px 0px;
		margin-bottom: 30px;
		line-height: 40px;
		font-size: 16px;
		color: #fff;
		text-align: left;

		>div {
			// margin-bottom: 5px;
		}
	}
}

/* #ifdef MP-ALIPAY */
::v-deep.u-status-bar {
	height: auto !important;
}

::v-deep.u-navbar-placeholder {
	height: auto !important;
}

/* #endif */
</style>
