<template>
	<div class="chat-app">
		<div class="message-list">
			<div class="divBox">
				<div v-for="message in messages" class="message">
					<span class="sender" v-if="message.role != 'system'">{{ message.role }}:</span>
					<span class="content" v-if="message.content != 'You are a helpful assistant.'">{{ message.content
					}}</span>
				</div>
			</div>

		</div>
		<div class="input-box">
			<input v-model="newMessage" type="text" placeholder="请输入消息" @keyup.enter="sendMessage" />
			<button @click="sendMessage">发送</button>
		</div>
	</div>
</template>
  
<script>
export default {
	data() {
		return {
			messages: [{
				'role': 'system',
				'content': 'You are a helpful assistant.'
			}],
			newMessage: ''
		};
	},
	methods: {
		sendMessage() {

			const users = {
				'role': 'user',
				'content': this.newMessage
			}
			this.messages.push(users)
			this.newMessage = '';

			this.postRequest(this.constant.loadurl.chat_ai_gpt, this.qs.stringify({
				msg: JSON.stringify(this.messages),
			})).then(res => {
				if (res.data.code == 0 && res.data.data != undefined) {
					const message = {
						'role': 'assistant',
						'content': res.data.data
					}
					this.messages.push(message)
				}
			})
			console.log(this.messages, '---->>>');
		}
	}
}
</script>
  
<style lang="scss">
.chat-app {
	height: 100vh;
	position: relative;
}

.message-list {

	.divBox{
	width:98% ;
	margin: 0 auto;
	max-height: 56.25rem;
	overflow-y: scroll;
	}
}

.message {

	margin-bottom: 10px;

}

.sender {
	font-weight: bold;
}

.content {
	margin-left: 5px;
}

.input-box {
	position: absolute;
	bottom: 0;
	width: 100%;
	display: flex;
	margin-top: 10px;
}

input {
	flex: 1;
	padding: 10px;
}

button {
	padding: 5px 10px;
}</style>
  