import axios from 'axios'

//封装传送json的post请求
export const postRequest = (url, params) => {
	return axios({
		method: 'post',
		url: url,
		data: params
	})
}
//封装传送json的get请求
export const getRequest = (url, params) => {
	return axios({
		method: 'get',
		url: url,
		data: params
	})
}
//封装传送json的put请求
export const putRequest = (url, params) => {
	return axios({
		method: 'put',
		url: url,
		data: params
	})
}
//封装传送json的delete请求
export const deleteRequest = (url, params) => {
	return axios({
		method: 'delete',
		url: url,
		data: params
	})
}
