
// var baseUrl = "https://chat.zhyell.com/api/"; // 测试环境
var baseUrl = "https://chatonline.zhyell.com/api/"; // 正式环境
export default {
	title: 'ai',
	loadurl: {
		login_pwd: baseUrl + 'home/login_pwd', //登录
		get_application_setting: baseUrl +  'home/get_application_setting', //获取企业应用色值
		set_answer: baseUrl +  'home/set_answer', //记录问题次数
		get_answer: baseUrl + 'home/get_answer',// 问题答案
		get_answer_info:baseUrl + 'home/get_answer_info',//模糊查询最后的问题目前未使用
		get_answer_like:baseUrl + 'home/get_answer_like',// 猜你想问
		set_leave_message:baseUrl + 'home/set_leave_message',// 留言
		chat_ai_gpt:baseUrl + 'home/chat_ai_gpt'//GPT接口
	
 	}
}
