<template>
  <div id="app">

    <router-view></router-view>
  </div>
</template>

<script>
import { HemisphereLightProbe } from 'three';


export default {
  
  // components: {

  // },
  data(){
    return{
      msg:'',
      key:''
    }
  },
  mounted(){
    	// 访问时是否带key
		if (this.$route.query.key) {
			this.key = this.$route.query.key;
		} else {
			this.key = ''
		}

    // if (this._isMobile()) {
    //   console.log('app');
    //   this.$router.push('/digtai?key=' + this.key)
    // } else {
    //   this.$router.push('/index?key=' + this.key)
    //   console.log('pc');
    // }
  },
  methods:{
    _isMobile() {
	 let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	 return flag;
    }
  }

}
</script>

<style>
#app {
  width: 100%;
  margin:0;
  padding: 0;
  /* overflow: hidden; */
  /* text-align: center; */
}
</style>
