<template>
	<div class="yhxy">
		<div class="back" @click="back">
			<i class="el-icon-arrow-left"></i>
			返回
		</div>
		<div class="paragraph">
			<div style="text-align:center;width:100%;">
				<h1>嗨媒体AI平台隐私协议</h1>
				<div>
					欢迎您使用嗨媒体AI平台（以下简称：“本平台”）！您使用我们的服务即表示您已同意本服务协议。 请仔细阅读并透彻理解本协议内容，特别是免除或者限制责任的条款，我们将以加粗形式提醒您注意。如果您不同意本协议或其中任何条款约定，请您立即停止使用本平台。
本平台的服务范围非常广泛，并将保持更新。某些产品/服务可能会适用一些附加条款（包括但不限于《免责声明》等）。 附加条款将会与相关产品/服务一同提供，并且在您实际使用这些服务后，成为您与我们所达成的协议的一部分。
我们会根据有关法律法规的变化、公司经营状况、经营策略调整和产品服务升级等原因修订本协议。更新后的协议条款公布后即有效代替原来的协议条款，您可随时使用本平台查阅最新版用户协议。如果您不同意协议改动内容，请您立即停止使用本平台。如果您继续使用则视为接受本协议的改动。
若您是18周岁以下的未成年人，建议您请您的监护人仔细阅读本协议，并在征得您的监护人同意的前提下使用本平台。
				</div>
				<h2>一、本平台的服务内容及范围</h2>
				<h3>1.1 服务内容</h3>
				<div>
					本平台拥有跨领域的知识和自然语言理解能力。它可以与用户聊天互动，生成图片。通过使用嗨媒体AI平台，你可以体验到全新的人机协作服务，获得想象力和创造力。
				</div>
				<h3>1.2 服务许可的范围</h3>
				<div>1.2.1 我们给予您一项个人的、不可转让及非排他性的许可以使用本平台。您可以为非商业目的在多台终端设备上安装、使用、显示、运行本平台。未经的事先书面授权和许可，您不得利用本平台谋取不正当利益，不得利用本平台提供的资源和信息自行或者帮助他人生成、获取、传播违法信息或进行商业性的销售、复制、散发或其他商业活动。</div>
				<div>
					1.2.2 本条及本协议其他条款未明示授权的其他一切权利仍由保留，您在行使这些权利时须另外取得的书面许可。如果未行使前述任何权利，并不构成对该权利的放弃。
				</div>
				<h2>二、本平台服务的使用</h2>
				<h3>2.1 产品/服务的更新</h3>
				<div>2.1.1 为了改善用户体验、完善服务内容，将不断努力开发新的服务，并为您不时提供产品/服务更新（这些更新可能会采取产品/服务替换、修改、功能强化、版本升级等形式）。</div>
				<div>2.1.2 为了保证本产品及服务的安全性和功能的一致性，我们可能对产品/服务进行更新，或者对产品/服务的部分功能效果进行改变或限制。</div>
				<div>2.1.3 您可以随时停止使用我们的服务，也可能随时停止向您提供服务，或随时对我们的服务增加或设置新的限制。</div>
				<h2>三、用户行为规范及注意事项</h2>
				<h3>3.1 账号注册</h3>
				<div>本平台提供的[文生文及文生图]服务，使用过程中，您应当同意《嗨媒体AI平台用户协议》、《嗨媒体AI平台隐私政策》并按照页面上的提示完成全部程序。用户应当对以其用户帐号进行的所有活动和事件负法律责任，包括您所发表的任何内容以及由此产生的任何后果。</div>
				<h3>3.2 产品/服务使用规范</h3>
				<div>3.2.1 您保证，不得并禁止直接或间接的：</div>
				<div>3.2.1.1 利用本平台提供的资源和信息进行商业性的销售、复制、散发或其他商业活动；：</div>
				<div>3.2.1.2 以任何方式干扰或企图干扰本平台或任何部分或功能的正常运行；</div>
				<div>3.2.1.3 未获得事先书面同意以书面格式或图形方式使用源自的任何注册或未注册的商品、服务标志、公司商标 (LOGO) 、 URL或其他标志；</div>
				<div>3.2.1.4 未事先经过原始用户的同意，而向任何非原始用户显示或以其他方式提供任何用户信息；</div>
				<div>3.2.1.5 请求、收集、索取或以其他方式从任何用户那里获取对身份验证凭据的访问权；</div>
				<div>3.2.1.6 未经授权冒充他人或非法获取本平台访问权；</div>
				<div>3.2.1.7 未经书面许可，任何用户、第三方均不得自行或授权、允许、协助他人对本平台相关产品及服务中信息内容进行如下行为：</div>
				<div>（1）复制、读取、采用本平台相关产品及服务的信息内容，用于包括但不限于宣传、增加阅读量、浏览量等商业用途；</div>
				<div>（2）擅自编辑、整理、编排本平台相关产品及服务的信息内容后在任何未经事先许可的第三方渠道进行展示；</div>
				<div>（3）采用包括但不限于特殊标识、特殊代码等任何形式的识别方法，自行或协助第三人对本平台相关产品及服务的信息内容产生流量、阅读量引导、转移、劫持等不利影响；</div>
				<div>（4）其他非法获取或使用本平台相关产品及服务信息内容的行为。</div>
				<div>3.2.1.8 未经事先书面许可，任何用户、第三方不得以任何方式（包括但不限于盗链、非法抓取、模拟下载、深度链接、假冒注册等）直接或间接盗取本平台任何信息内容，或以任何方式（包括但不限于隐藏或者修改域名、平台特有标识、用户名等）删除或改变相关信息内容的权利管理电子信息。</div>
				<div>3.2.1.9 经书面许可后，用户、第三方对本平台相关产品及服务信息内容的分享、转发等行为，还应符合以下规范：</div>
				<div>（1）不得对本平台源网页进行任何形式的任何改动，包括但不限于官网首页等入口，也不得对本平台源页面的展示进行任何形式的遮挡、插入、弹窗等妨碍；</div>
				<div>（2）应当采取安全、有效、严密的措施，防止本平台相关产品及服务的信息内容被第三方通过包括但不限于 “ 蜘蛛 ” （spider ）程序等任何形式进行非法获取；</div>
				<div>（3）不得把相关数据内容用于书面许可范围之外的目的，进行任何形式的销售和商业使用，或向第三方泄露、提供或允许第三方为任何方式的使用。</div>
				<div>3.2.1.10用户违反上述任何一款的保证，均有权就其情节，对其做出警告、屏蔽、直至取消本平台使用资格的处罚；如因用户违反上述保证而给本平台、本平台其他用户或的任何合作伙伴造成损失，用户自行负责承担一切法律责任并赔偿损失。</div>
				<div>3.2.2 您承诺：</div>
				<div>3.2.2.1 您不得为任何非法目的而使用本平台网络服务系统；不得以任何形式使用本平台服务侵犯的商业利益，包括并不限于发布非经事先许可的商业广告；不得利用本平台进行任何可能对互联网或移动网正常运转造成不利影响的行为；</div>
				<div>3.2.2.2 您不得利用本平台服务从事以下活动：</div>
				<div>（1）未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</div>
				<div>（2）未经允许，对计算机信息网络功能进行删除、修改或者增加的；</div>
				<div>（3）未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</div>
				<div>（4）故意制作、传播计算机病毒等破坏性程序的；</div>
				<div>（5）其他危害计算机信息网络安全的行为。</div>
				<h3>3.3 信息内容规范</h3>
				<div>3.3.1 本协议所述信息内容是指您使用本平台服务过程中所制作、复制、发布、传播的任何内容，包括但不限于AI数字员工帐号头像、账号名称等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用本平台服务所产生的内容。</div>
				<div>3.3.2 您理解并同意，您在此平台的言行必须遵守相关法律法规，不得进行任何违法或不正当的活动或利用本平台提供的资源和信息自行或者帮助他人生成、获取、传播以下信息：反对宪法所确定的基本原则的危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；损害国家荣誉和利益的；煽动民族仇恨、民族歧视、破坏民族团结的；破坏国家宗教政策，宣扬邪教和封建迷信的；散布谣言，扰乱社会秩序，破坏社会稳定的；散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；侮辱或者诽谤他人，侵害他人合法权利的；煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；以非法民间组织名义活动的；含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。</div>
				<div>3.3.3如因您利用本平台上传、制作、传送或通过其他方式传播侵犯了第三方的合法权益（包括但不限于专利权、商标权、著作权及著作权邻接权、肖像权、隐私权、名誉权等）而导致或与合作的其他单位面临任何投诉、举报、质询、索赔、诉讼，或者使或者与合作的其他单位因此遭受任何名誉、声誉或者财产上的损失，您应积极地采取一切可能采取的措施，以保证及与合作的其他单位免受上述索赔、诉讼的影响。同时您对及与合作的其他单位因此遭受的直接及间接经济损失负有全部的损害赔偿责任。</div>
				<div>3.3.4 您不得利用基于深度学习、虚拟现实等新技术新应用制作、发布、传播虚假信息。您在发布或传播非真实信息时，应当以显著方式予以标识。</div>
				<div>3.3.5您知悉并理解，本平台输出内容是人工智能生产内容。所有生成内容来源于我们训练时所使用的有限数据，相关回答不代表的态度或观点，不应视为互联网新闻信息，也不能作为专业性建议或意见。您应对本平台服务中的内容应自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的合法性、正确性、完整性或实用性的依赖而产生的风险。无法且不会对因前述风险而导致的任何损失或损害承担责任。</div>
				<h3>3.4 平台管理规范</h3>
				<div>3.4.1 任何用户发现本平台内容涉嫌侮辱或者诽谤他人、侵害他人合法权益、或违反本协议相关条款的，有权通过本协议第七条约定的渠道向本平台进行投诉。本平台有权不经通知随时对相关内容进行删除，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁直至注销的处罚，并公告处理结果。</div>
				<div>3.4.2 您理解并同意，本平台有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。</div>
				<div>3.4.3 您理解并同意，因您违反本协议或相关法律的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；因此遭受损失的，您也应当一并赔偿。</div>
				<div>3.4.4 您在使用本平台某些独立产品/服务时可能会另有单独的协议、相关业务规则等（以下统称为 “ 单独协议 ” ），您在使用该项服务前请阅读并同意相关的单独协议；您使用前述特定服务，即视为您接受相关单独协议。</div>
				<div>3.4.5第三方产品和服务</div>
				<div>3.4.5.1 您在使用本平台中由第三方提供的产品或服务时，请您遵守第三方的用户协议。不对因第三方网站及相关服务所可能导致的风险或可能出现的纠纷承担责任。</div>
				<div>3.4.5.2 用户使用某些特定服务时，本平台可能会调用第三方系统或者通过第三方支持用户的使用或访问，使用或访问的结果由该第三方提供。不保证通过第三方提供服务及内容的安全性、准确性、有效性及其他不确定的风险，由此若引发的任何争议及损害，不承担任何责任。</div>
				<div>3.4.5.3 您理解并同意，有权决定将本平台用作商业用途，包括但不限于开发、使用本平台的部分服务为第三方作推广等，但是这并不意味着对第三方的产品或者服务承担任何法律责任。</div>
				<h3>四、用户个人信息保护</h3>
				<div>4.1 保护用户个人信息是的一项基本原则。《嗨媒体AI平台隐私政策》介绍了您在使用本平台时如何收集和使用您的个人信息。您使用本平台的产品的服务，即表示您同意《嗨媒体AI平台隐私政策》。</div>
				<div>4.2 您在申请体验本平台相关服务的过程中，需要填写一些必要的信息，请保持这些信息的真实、准确、合法、有效并注意及时更新。若您填写的信息不完整或不准确，则可能无法使用本平台相关服务或在使用过程中受到限制。</div>
				<div>4.3 您应对通过本平台了解、接收或可接触到的包括但不限于其他用户在内的任何人的个人信息予以充分尊重，您不应以搜集、复制、存储、传播或以其他任何方式使用其他用户的个人信息，否则，由此产生的后果由您自行承担。</div>
				<h3>五、知识产权及相关权益</h3>
				<div>5.1 保留对以下各项内容、信息完全的、不可分割的（包括但不限于）所有权、知识产权和/或其他合法权益：</div>
				<div>5.1.1 本平台所有相关产品/服务及其所有元素，包括但不限于所有内容、数据、技术、软件、代码、用户界面、商标、logo等商业标识以及与其相关的任何衍生作品；</div>
				<div>5.1.2 用户向本平台提供的与该服务相关的任何信息及反馈。</div>
				<div>5.2 未经同意或协议另有明确约定，上述资料均不得在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者被用于其他任何商业目的。不就由上述资料产生或在传送或递交全部或部分上述资料过程中产生的延误、不准确、错误和遗漏或从中产生或由此产生的任何损害赔偿，以任何形式向用户或任何第三方负法律、经济责任。</div>
				<div>5.3 本平台为提供网络服务而使用的任何软件（包括但不限于软件中所含的任何图像、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料）的一切权利均属于该软件的著作权人，未经该软件的著作权人许可，用户不得对该软件进行反向工程（reverse engineer）、反向编译（decompile）或反汇编（disassemble），或以其他方式发现其原始编码，以及实施任何涉嫌侵害著作权的行为。</div>
				<div>5.4 用户在使用本平台中所产生的内容的知识产权归或相关权利人所有，用户通过本产品平台发布的内容（以下统称为“公开发布内容”）经过本平台审核通过，一经发布即向公众传播和共享。</div>
				<div>5.5 任何未经书面同意及权利人许可的非法获取行为，均属违法侵权行为。您确认并同意，为及时、有效地保障您基于本服务的合法权益，您特别授权在发现您的合法权益（包括但不限于信息网络传播权、著作权等）可能受到侵害时，有权以自己的名义或委托专业第三方机构代您向涉嫌侵权的第三人采取法律手段进行维权，特别授权采取的维权手段包括但不限于侵权监控、发函警告、行政举报、提起诉讼、申请仲裁、移送侦查机关处理、调解、和解等。同时，请您注意，您对的上述授权，并不意味着与您之间存在任何利益共享机制。</div>
				<div>5.6 您理解并且同意，为持续改善为您提供的各项服务，除非有相反证明， 您使用本平台服务上传、发布或传输内容即代表了您有权且同意在全世界范围内，永久性的、不可撤销的、免费的授予及其关联方的全部产品或服务对该内容的存储、使用、发布、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示等权利；将内容的全部或部分编入其他任何形式的作品、媒体、技术中的权利；对您的上传、发布的内容进行商业开发的权利；通过有线或无线网络向计算机终端、移动通讯终端（包括但不限于便携式通讯设备如手机和智能平板电脑等）、手持数字影音播放设备、电视接收设备（模拟信号接收设备、数字信号接收设备、数字电视、IPTV、电视机机顶盒、视频投影仪、车载电视、互联网电视/OTT终端、智能电视等带互联网接入功能的播放设备等）等提供信息的下载、点播、数据传输、移动视频业务（包括但不限于SMS、MMS、WAP、IVR、Streaming、手机视频等无线服务）及其相关宣传和推广等服务的权利；以及再授权给其他第三方以上述方式使用的权利。</div>
				<div>5.7 本平台所包含的内容的知识产权均受到法律保护，未经过、用户或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。</div>
				<h3>六、免责声明</h3>
				<div>6.1 鉴于网络服务的特殊性，用户同意本平台产品/服务随时变更、中断或终止部分或全部的网络服务。如变更、中断或终止的网络服务属于免费网络服务，本平台无需通知用户，也无需对任何用户或任何第三方承担任何责任。</div>
				<div>6.2 您理解，本平台产品/服务需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成收费网络服务在合理时间内的中断，本平台无需为此承担任何责任，但应尽可能事先进行通告。</div>
				<div>6.3 本平台可在任何时候为任何原因变更平台服务内容或删除其部分功能。本平台可在任何时候取消或终止对用户的服务，且该决定不需要理由或通知用户。一旦服务取消，用户使用相关服务的权利立即终止。一旦服务取消或终止，用户在服务中储存的任何信息可能无法恢复。</div>
				<div>6.4 以下情形本平台不保证（包括但不限于）：</div>
				<div>（1）相关产品/服务适合用户的使用要求；</div>
				<div>（2）本平台服务不受干扰，及时、安全、可靠或不出现错误，用户经由本平台服务取得的任何产品、服务或其他材料符合用户的期望；</div>
				<div>（3）您使用经由本平台服务取得的任何资料，其风险由您自行承担；因该等使用导致您的电脑系统损坏或资料流失，您应自己负完全责任。</div>
				<div>6.5 基于以下原因而造成的利润、商业信誉、资料损失或其他有形或无形损失，不承担任何直接、间接的赔偿：</div>
				<div>（1）对本平台服务的使用或无法使用；</div>
				<div>（2）经由本平台服务购买或取得的任何产品、资料或服务；</div>
				<div>（3）您的资料遭到未授权的使用或修改；及其他与本平台服务相关的事宜。</div>
				<div>6.6 由于您授权第三方（包括第三方应用）访问/使用其本平台服务所导致的纠纷或损失，应由您自行承担。</div>
				<div>6.7基于用户体验或本平台运营安全、健康发展等综合因素，有权选择提供服务或开展合作的对象，有权决定功能开放、数据接口和相关数据披露的对象和范围，并有权视具体情况中止或终止向存有包括但不限于以下情形的用户提供本服务：</div>
				<div>（1）违反法律法规或本协议规定的；</div>
				<div>（2）影响用户体验的；</div>
				<div>（3）存在安全隐患的；</div>
				<div>（4）不符合本公司管理要求的。</div>
				<h3>七、未成年人使用条款</h3>
				<div>7.1 若您是未满18周岁的未成年人，您应在您的监护人监护、指导下并获得监护人同意的情况下，认真阅读并同意本协议后，方可使用本平台相关产品及服务。</div>
				<div>7.2 本平台重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待。</div>
				<div>7.3 未成年人用户及其监护人理解并确认，如您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而可能导致的全部法律责任。</div>
				<div>7.4 监护人特别提示：如您的被监护人使用本平台相关产品/服务的，您作为监护人应指导并监督被监护人的注册和使用行为。将有权认为其已取得您的同意。</div>
				<h3>八、其他</h3>
				<div>8.1本协议的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖。如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向被告住所地有管辖权的人民法院提起诉讼。</div>
				<div>8.2如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</div>
				<div>8.3您在使用本平台过程中有任何意见、建议或投诉，均可通过官方电子邮箱【hbsxxxjs@126.com】与我们取得联系，我们将在15个工作日内进行回复。</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
			};
		},
		methods:{
			back(){
				this.$router.go(-1) 
			}
		}
	}
</script>

<style lang="scss">
	.yhxy{
		padding:20px 30px 0px 30px;
		display: block;
		background-color: #333;
		.back{
			
			color:#ffffff;
			text-align: left;
		}
		.paragraph{
			padding: 30px 0px;
			margin-bottom: 30px;
			line-height: 40px;
			font-size: 16px;
			color: #fff;
			text-align: left;
			>div{
				margin-bottom: 30px;
			}
		}
		/* #ifdef MP-ALIPAY */
			::v-deep.u-status-bar {
				height: auto !important;
			}
			::v-deep.u-navbar-placeholder{
				height: auto !important;
			}
		
		/* #endif */
	}
	
	
</style>
