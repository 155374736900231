<template>
	<div class="content">
		<div class="cont-tit">
			<div>Hi!</div>
			<div>欢迎登录~</div>
		</div>

		<div class="loginsty">
			<div class="iteminput">
			  <el-input type="number" max="11" placeholder="请输入您的手机号码" v-model="phone">
			    <template slot="prepend">手机</template>
			  </el-input>
			</div>
			<div class="iteminput">
			  <el-input show-password placeholder="请输入密码" v-model="password">
			    <template slot="prepend">密码</template>
			  </el-input>
			</div>
			<div class="boxfourbtn" @click="logintd">登录</div>
			<div class="xieyi">
				<template>
					<!-- `checked` 为 true 或 false -->
					<el-checkbox @change="yxchange" v-model="checked"></el-checkbox>
				</template>
				<div>登录即代表同意</div>
				<div @click="yhxy" class='xy'>《用户协议》</div>
				<div>和</div>
				<div @click="ysxy" class='xy'>《隐私政策》</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				phone: "",
				password: "",
				checked: false,
			}
		},
		onLoad() {},
		methods: {
			yxchange(e) {
				this.checked = e
			},
			yhxy() {
				this.$router.push({
					name: "yhxy"
				})
			},
			ysxy() {
				this.$router.push({
					name: "ysxy"
				})
			},
			// 点击登录
			logintd() {
				if (this.phone == '' || this.password == '') {
					this.$message({
						message: '手机号或密码不能为空',
						type: 'warning',
						center: true
					});
				} else {
					// 如果手机号密码验证通过
					if (this.checked == true) {
						var vm = this;
						vm.postRequest(vm.constant.loadurl.login_pwd, vm.qs.stringify({
							phone: this.phone,
							password: this.password,
						})).then(res => {
							if (res.data.code == 0) {
								var data = res.data.data
								// 存储token，用户名称以及编号ID
								localStorage.setItem("users", JSON.stringify(data.user))
								localStorage.setItem("token", data.token)
								this.$router.push({
									name: "new_digtai"
								})
							} else {
								// vm.message = res.data.message;
								this.$message({
									message:  res.data.message,
									type: 'warning',
									center: true
								});
							}
						})
					} else {
						this.$message({
							message: '请阅读并勾选页面协议',
							type: 'warning',
							center: true
						});
					}

				}

			},
		}
	}
</script>

<style scoped>
	.content {
		background: url("../../static/imgs/loginbg.png") no-repeat;
		background-size: 100%;
		width: 100%;
		display: block;
		margin: 0 auto;
		height: 100vh;
	}

	.cont-tit {
		margin-left: 1rem;
		padding-top: 5rem;
		padding: 10rem 0rem 0rem 2rem;
		color: #fff;
		font-size: 2rem;
		text-align: left;
	}

	.loginsty {
		position: absolute;
		top: 15rem;
		left: 2rem;
		right: 2rem;
		margin: 0 auto;
		
/* 		left: calc(100% / 2);
		right: calc(100%  / 2); */
		border-radius: 0.5rem;
		/* padding:0.5rem 0.3rem; */
	}


	.iteminput {
		display: flex;
		font-size: 1rem;
		color: #fff;
		line-height: 3rem;
		height: 3rem;
		align-items: center;
		background: #292933;
		margin: 2rem 0rem;
		border-radius: 1rem;
		padding: 0 0.2rem;
	}

	/deep/ .el-input-group__prepend {
		/* width: 3rem; */
		font-size:1rem;
		background: #292933;
		border: none;
	}
	/deep/ .el-input {
/* 	    width: 95%;
	    height: 40px; */
	    .el-input__inner {
		
	     background: #292933 !important;
	     border: none;
	      &::placeholder {
	        color: white;
	      }
	    }
	    .el-input__inner:before  {
	      
	      background: #292933 !important;
	      border: none;
	    }
	    .el-input__inner:focus {
	     
	     background: #292933 !important;
	     border: none;
		}
		}

	.zzpass {
		text-align: right;
		font-size: 1rem;
		color: #BBBBBB;
		opacity: 0.4;
		margin: -0.1rem 0.1rem 0.6rem 0.1rem;
	}


	.boxfourbtn {
		color: #FFFFFF;
		text-align: center;
		line-height:2.5rem;
		font-size: 1.2rem;
		background-image: linear-gradient(to right, #3c6aea, #42acec);
		background-size: 100% 100%;
		width: 100%;
		height: 2.5rem;
		margin: 0.5rem auto;
		border-radius: 0.55rem;
	}

	.xieyi {
		font-size: 0.8rem;
		margin-top: 0.8rem;
		display: flex;
		align-items: center;
		color: #999999;
	}
/* 	/deep/ .el-checkbox__inner{
		width: 1.5rem;
		height: 1.5rem;
	} */
	.xy {
		color: #fff;
	}
</style>
