<template>
  <div style="position: relative;">
    <div v-if="isloaded == false" class="loading">
      <!-- <div class="loading"> -->
      <!-- <img class="loadImg" src="../assets/UI/loading.gif"> -->
      <span class="loadingSpan">数字人加载中...</span>
      <progress-bar size="tiny" :val="laodingNum" :text="loadingText" text-fg-color="#ffffff"
        style="margin-bottom: 150px;"></progress-bar>
    </div>
    <div ref="container">
    </div>
    <!-- <audio ref="yinpin" autoplay @ended="audioEnd()" src="../../static/Audios/pjk.mp3"></audio> -->
  </div>
</template>
<script>
import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
// import progress from '../components/Progress.vue'
import ProgressBar from 'vue-simple-progress'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
export default {
  name: '',
  mixins: [],
  components: {
    // progress
    ProgressBar
  },
  props: {
    environment: '', // 环境光 默认值 1.2
    parallel: '', // 平行光 默认值1.3 
    count: '',
    modelURL: '',
    seat: '' //  0 中景 1 中全景 2 全景
  },
  data() {
    return {
      scene: null,
      renderer: null,
      camare: {},
      model: null,
      element: null,
      mixer: null,
      clock: null,
      audioContext: null,
      analyser: null,
      morphTargets: [],
      beforMorphTargets: [],
      isEnd: false,
      audioElement: null,
      isTouch: false,

      isplay: false,

      baishou: null,
      fangshou: null,
      youshoujieshao: null,
      zhuantou: null,
      zuoshoujieshao: null,
      diantou: null,
      tanshou: null,

      // glbUrl: "https://chatoss.zhyell.com/0AModels/zhengtidaochu01.glb",
      // glbUrl: "https://chatoss.zhyell.com/0AModels/nv.glb",
      glbUrl: "https://minio.zhyell.com/fwy/modules/nv.glb",
      // glbUrl:"https://chatoss.zhyell.com/0AModels/nan.glb",

      animations: [],

      loadingText: "",
      isloaded: false,
      laodingNum: 0,

      cremeraPos: null,
      modlePos: null,

      ALight: 0.7, // 环境光
      DLight: 0.7, // 平行光

      zui1: null,
      zui2: null,
      zui3: null,
    }
  },
  computed: {},
  watch: {
    environment: {
      handler(v) {
        this.environment = v
        this.Setlight(this.environment, this.parallel)
      },
      deep: true
    },
    parallel: {
      handler(v) {
        this.parallel = v
        this.Setlight(this.environment, this.parallel)
      },
      deep: true
    },
    seat: {
      handler(n) {
        this.seat = n
        // this.setCameraPos(n)
      },
      deep: true
    },
  },
  created() {
    this.cremeraPos = new THREE.Vector3(0, 0.2, 1);
    this.modlePos = new THREE.Vector3(0, 0.37, 0);

    if (this.modelURL) {
      this.glbUrl = this.modelURL
    } else {
      this.glbUrl = 'https://chatoss.zhyell.com/0AModels/nv1.glb'
      // this.glbUrl = 'https://minio.zhyell.com/fwy/modules/nv.glb'
    }
    // this.setCameraPos(2)
    // console.log(this.modelURL,'-modelURLmodelURLmodelURL');
  },
  mounted() {
    this.isTouch = false;
    this.clock = new THREE.Clock();
    // this.$nextTick(() => {   
    this.init();
    this.animate();
    // });
    window.audioContextPlay = this.audioContextPlay;
    window.offTouch = this.offTouch;
    window.setCameraPos = this.setCameraPos;

  },
  methods: {
    setCameraPos(num){
      // let id = parseInt(num);
      let id = num;
      // console.log("位置选择 === ",num,id)
        if(id == 0){  // 近
          this.cremeraPos = new THREE.Vector3(0, 0.13, 0.6);
          this.modlePos = new THREE.Vector3(0,0.0,0);
        }else if(id == 1){  // 中
          this.cremeraPos = new THREE.Vector3(0, 0.2, 0.8);
          this.modlePos = new THREE.Vector3(0,0.1,0);
        }else if(id == 2){  // 远
          this.cremeraPos = new THREE.Vector3(0, 0.35, 1);
          this.modlePos = new THREE.Vector3(0,0.35,0);
        }
        this.setCamare_position();
//          console.log("摄像机位置",this.cremeraPos,"模型位置",this.modlePos);
      },
    setCamare_position() {
      this.camare.position.set(this.cremeraPos.x, this.cremeraPos.y, this.cremeraPos.z);
      if(this.model != null){
					this.model.position.set(this.modlePos.x,this.modlePos.y,this.modlePos.z)
			}
    },
    Setlight(env, par) {
      var light = new THREE.AmbientLight(0xffffff, env);
      this.scene.add(light);

      // 平行光
      var light1 = new THREE.DirectionalLight(0xffffff, par);
      light1.position.set(100, 100, 300);
      light1.castShadow = true;
      this.camare.add(light1);


      light1.shadow.bias = -0.0002;
      light1.shadow.camera.near = 0.3;
      light1.shadow.camera.far = 8000;
      light1.shadow.camera.fov = 6000;
    },
    // 播放完毕
    offTouch() {
      this.isTouch = false;
      // console.log("播放完毕");
    },
    /**
     * @method 音频播放
     */
    audioContextPlay(analyser, source) {
      let self = this;
      // console.log("进入人物语音播放",analyser,source)
      self.analyser = analyser;

      self.isTouch = true;

      self.playAnimation();

      source.connect(self.analyser);
      // console.log("进入语音播放 ===== 》",self.isTouch,self.isEnd,source,self.analyser)
    },

    playAnimation() {
      let random = Math.random();
      // let random = 0.72;
      this.baishou.stop();
      this.biyan.stop();
      this.fangshou.stop();
      this.youshoujieshao.stop();
      this.zhuantou.stop();
      this.zuoshoujieshao.stop();
      this.diantou.stop();
      this.tanshou.stop();
      if (this.isTouch == true) {
        if (random >= 0.14 && random < 0.28) {
          this.youshoujieshao.time = 7.72;
          this.youshoujieshao.play();
        } else if (random >= 0.28 && random < 0.42) {
          // this.zuoshoujieshao.time = 5.88;
          this.zuoshoujieshao.play();
        } else if (random >= 0.42 && random < 0.56) {
          this.zhuantou.time = 5.3;
          this.zhuantou.play();
        } else if (random >= 0.56 && random < 0.7) {
          this.diantou.time = 21;
          this.diantou.play();
        } else if (random >= 0.7 && random < 0.84) {
          this.biyan.time = 26.6;
          this.biyan.play();
        } else if (random >= 0.84 && random < 1) {
          this.fangshou.play();
        } else if (random < 0.16) {
          // this.baishou.play();
          this.tanshou.time = 10.65;
          this.tanshou.play();
        }
      } else {
        if (random >= 0.25 && random < 0.5) {
          this.zhuantou.time = 5.3;
          this.zhuantou.play();
        } else if (random >= 0.5 && random <= 0.75) {
          this.diantou.time = 21;
          this.diantou.play();
        } else if (random >= 0.75 && random <= 1) {
          // this.baishou.play();
          this.fangshou.play();
        } else if (random < 0.25) {
          // this.baishou.play();
          this.biyan.time = 26.6;
          this.biyan.play();
        }
      }
    },

    init() {
      this.isEnd = false;
      this.scene = new THREE.Scene();
      this.element = this.$refs.container;
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.shadowMap.enabled = true;
      this.renderer.setClearColor(0x101b29, 0); // 设置背景颜色
      this.$refs.container.append(this.renderer.domElement);

      window.addEventListener("resize", this.onWindowResize, false);

      this.initCamare();
    },

    initCamare() {
      // console.log("初始化摄像机",this.cremeraPos,this.cremeraPos.x)

      this.camare = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
      this.camare.near = 0.01;
      this.camare.far = 2000;
      // this.camare.position.set( 0, 0.2, 0.6 );
      this.camare.position.set(this.cremeraPos.x, this.cremeraPos.y, this.cremeraPos.z);
      // this.camare.position.set( this.cremeraPos);
      // this.camare.position.z = 70;
      this.scene.add(this.camare);
      // 光线
      // 环境光
      var light = new THREE.AmbientLight(0xffffff, this.environment);
      this.scene.add(light);

      // 平行光
      var light1 = new THREE.DirectionalLight(0xffffff, this.parallel);
      light1.position.set(100, 100, 300);
      light1.castShadow = true;
      this.camare.add(light1);


      light1.shadow.bias = -0.0002;
      light1.shadow.camera.near = 0.3;
      light1.shadow.camera.far = 8000;
      light1.shadow.camera.fov = 6000;
      this.initOrbitControls();
      this.loadGLTF();
      // this.initOrbitControls();

    },
    // 初始化环形控制
    initOrbitControls() {
      this.controls = new OrbitControls(this.camare, this.renderer.domElement);
      this.controls.minDistance = 0.1;
      this.controls.maxDistance = 5;
      this.controls.enableZoom = false;
      this.controls.autoRotate = false;
      this.controls.enableRotate = false;

      // console.log("初始化摄像机控制");
    },
    onWindowResize() {
      this.camare.aspect = this.renderer.domElement.clientWidth / this.renderer.domElement.clientHeight;
      this.camare.updateProjectionMatrix();
    },
    // 持续渲染
    animate() {
      this.onWindowResize();
      requestAnimationFrame(this.animate);
      const delta = this.clock.getDelta();
      if (this.mixer) this.mixer.update(delta);
      this.render();
      // console.log("持续渲染");
    },
    // 渲染
    render() {
      this.setCameraPos(this.seat);
      // this.setCameraPos(2);
      if (this.isEnd == true && this.isTouch == true) {
        // 计算权重
        var freqData = new Uint8Array(this.analyser.frequencyBinCount);
        this.analyser.getByteFrequencyData(freqData);
        // console.log("计算权重",freqData)

        // 计算音量
        var volume = 0;
        for (var i = 0; i < freqData.length; i++) {
          volume += freqData[i];
        }
        volume /= freqData.length;

        // 更新morph targets
        this.morphTargets[0] = volume / 128; // 第一个morph target控制嘴巴的张合程度
        this.morphTargets[1] = volume / 128; // 第一个morph target控制嘴巴的张合程度
        this.morphTargets[2] = volume / 128; // 第一个morph target控制嘴巴的张合程度
        // this.morphTargets[3] = volume / 256; // 第一个morph target控制嘴巴的张合程度

        // console.log("this.morphTargets[0] ==== >",this.morphTargets[0])

        // 更新权重
        for (var i = 0; i < this.zui1.morphTargetInfluences.length; i++) {
          this.zui1.morphTargetInfluences[i] = this.morphTargets[0];
          this.zui2.morphTargetInfluences[i] = this.morphTargets[1];
          this.zui3.morphTargetInfluences[i] = this.morphTargets[2];
        }
      } else if (this.isEnd == true && this.isTouch == false) {
        // console.log("重置",this.beforMorphTargets[0])
        // 更新权重
        for (var i = 0; i < this.zui1.morphTargetInfluences.length; i++) {
          this.zui1.morphTargetInfluences[i] = this.beforMorphTargets[i];
          this.zui2.morphTargetInfluences[i] = this.beforMorphTargets[i];
          this.zui3.morphTargetInfluences[i] = this.beforMorphTargets[i];
        }
      }
      if (this.mixer) {
        this.mixer.update(this.clock.getDelta());
      }

      this.renderer.render(this.scene, this.camare);
    },

    loadGLTF() {
      let self = this;
      let loadNum = 0;
      var manager1 = new THREE.LoadingManager();

      manager1.onProgress = function (item, loaded, total) {
        // console.log("进度管理", item, loaded, total);
        loadNum = (loaded / total) * 100;
        if (loadNum == 100) {
          self.isloaded = true;
          self.$emit('handleClick', true)

          // console.log('加载完成')
        }
      };
      var loader = new GLTFLoader(manager1)
      // console.log("模型链接",self.glbUrl)
      loader.load(self.glbUrl, function (gltf) {
        self.model = gltf.scene;
        self.model.position.set(self.modlePos.x, self.modlePos.y, self.modlePos.z);
        // console.log("加载模型", gltf);


        // self.model.lookAt(self.camare.position);
        // 添加模型到场景中
        self.scene.add(self.model);

        let test = self.model.getObjectByName("lian4");
        // console.log("test ============== 》", test);
        self.zui1 = self.model.getObjectByName("lian4_1");
        self.zui2 = self.model.getObjectByName("lian4_2");
        self.zui3 = self.model.getObjectByName("lian4_3");
        for (let i = 0; i < self.zui1.morphTargetInfluences.length; i++) {
          self.beforMorphTargets[i] = self.zui1.morphTargetInfluences[i];
        }

        self.mixer = new THREE.AnimationMixer(self.model);

        self.baishou = self.mixer.clipAction(gltf.animations[0]);
        self.baishou.loop = THREE.LoopOnce;

        self.biyan = self.mixer.clipAction(gltf.animations[1]);
        self.biyan.loop = THREE.LoopOnce;

        self.diantou = self.mixer.clipAction(gltf.animations[2]);
        // self.diantou.loop = THREE.LoopOnce;

        self.fangshou = self.mixer.clipAction(gltf.animations[3]);
        self.fangshou.loop = THREE.LoopOnce;

        self.tanshou = self.mixer.clipAction(gltf.animations[4]);
        // self.tanshou.loop = THREE.LoopOnce;

        self.youshoujieshao = self.mixer.clipAction(gltf.animations[5]);
        self.youshoujieshao.loop = THREE.LoopOnce;

        self.zhuantou = self.mixer.clipAction(gltf.animations[6]);
        self.zhuantou.loop = THREE.LoopOnce;

        self.zuoshoujieshao = self.mixer.clipAction(gltf.animations[7]);
        self.zuoshoujieshao.loop = THREE.LoopOnce;

        self.fangshou.play();
        self.mixer.addEventListener("finished", function (e) {
          // console.log("动画监听   finished === ",e);
          self.playAnimation();
        })
        self.mixer.addEventListener("loop", function (e) {
          // console.log("动画监听",e);
          self.playAnimation();
        })

        // console.log("模型 ==== ",self.model);
        self.morphTargets.push(self.zui1.geometry.clone()); // 第一个目标形状是原始形状
        self.morphTargets.push(self.zui2.geometry.clone()); // 第一个目标形状是原始形状
        self.morphTargets.push(self.zui3.geometry.clone()); // 第一个目标形状是原始形状

        self.isEnd = true;
        if (self.seat) {
          self.setCameraPos(self.seat)
        } else {
          self.setCameraPos(0)
        }
      }, function (xhr) {
        // 模型文件加载进度的回调函数
        self.laodingNum = xhr.loaded / xhr.total * 100;

        self.loadingText = (xhr.loaded / xhr.total * 100).toFixed(0) + " %";
        // if(self.laodingNum == 100){
        //   self.isloaded = true;
        //   self.$emit('handleClick',true)

        //   console.log('加载完成')
        // }
      },
      );
    },

  }
}
</script>
  
<style>
.loading {
  /* color: white;
    font-size: 20px; */
  position: absolute;
  /* z-index: 99; */
  /* align-items: center; */
  /* align-items: center; */
  /* align-items: center; */
  /* text-align: center; */
  /* display: flex;
    justify-content: center;
    align-items: center; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
}

.loadingSpan {
  z-index: 101;
  /* position: absolute; */
  width: 100%;
  height: 30px;
  color: white;
  font-size: 10px;
  line-height: 30px;
}

.loadImg {
  height: 102px;
  z-index: 100;
  position: absolute;
}
</style>
  