import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
Vue.config.productionTip = false
// eslint要求import系列代码都要在普通代码上边设置
// 引入elementui组件库
import ElementUI from 'element-ui'
// 引入css样式
import 'element-ui/lib/theme-chalk/index.css'

// 把组件库注册给Vue
// 之前组件注册：Vue.component(名称,组件) 每次只能注册一个
// 现在组件注册: Vue.use(组件模块) 一次性注册"全部"的组件，非常高效
Vue.use(ElementUI)
import constant from "./config/constant.js"
// 将constant挂在在prototype上挂载到Vue实例上面
Vue.prototype.constant=constant;
import qs from "qs"
Vue.prototype.qs=qs;
//全局引入axios
import axios from 'axios';
// import "./config/axios.js";
//注册axios组件
Vue.prototype.$http = axios;
import {postRequest} from "./config/api.js"
import {getRequest} from "./config/api.js"
import {putRequest} from "./config/api.js"
import {deleteRequest} from "./config/api.js"
// 因为element type number maxlength 不生效
Vue.directive('numberOnly', {
  bind: function(el) {  
    el.handler = function() {
      el.childNodes[1].value = el.childNodes[1].value.replace(/\D+/g, '');//因为是elementui组件里使用的el.value获取不到我们输入的内容。然后我发现他的第一个孩子才是我们输入的手机号码的内容。
    }
    el.addEventListener('keyup', el.handler); //这里使用keyup。我开始用的input发现第一次输入拼音字母竟然是可以输入的。改成keyup就都不能输入非数字了
  },
  unbind: function(el) {
    el.removeEventListener('keyup', el.handler);
  }

})
// 挂载到prototype的vue实例上面
Vue.prototype.postRequest = postRequest;
Vue.prototype.getRequest = getRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.deleteRequest = deleteRequest;
//注册axios组件
Vue.prototype.$http = axios;
new Vue({
 
  render: h => h(App),
  router,
}).$mount('#app')
